<template>
  <div>
    <validation-observer
      #default="{ invalid }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="onSubmit"
      >
        <!-- Measurement -->
        <b-form-group
          label="Measurements"
          label-for="measurements"
        >
          <vue-excel-editor
            v-model="formData.measurements"
            no-paging
            no-header-edit
            no-footer
            disable-panel-filter
            disable-panel-setting
            enter-to-south
            :spellcheck="false"
            :cell-style="cellStyle"
          >
            <vue-excel-column
              field="ref"
              label="REF"
              width="50px"
              type="string"
              readonly
              :init-style="{fontWeight: 'bold', textAlign: 'right'}"
            />
            <vue-excel-column
              field="description"
              label="Measurement"
              width="300px"
              type="string"
              readonly
            />
            <vue-excel-column
              field="tol"
              label="Tol (+/-)"
              width="75px"
              type="string"
              readonly
            />
            <template
              v-if="sampleRequestData.sample_size"
            >
              <vue-excel-column
                :field="sampleRequestData.sample_size"
                :label="sampleRequestData.sample_size"
                width="50px"
                type="string"
                readonly
              />
              <vue-excel-column
                field="measure"
                label="Fit"
                width="75px"
                type="string"
                :validate="validMeasure"
              />
            </template>
          </vue-excel-editor>
        </b-form-group>

        <b-form-group
          label="Measurement Approved"
          label-for="measurement-approved"
        >
          <b-form-checkbox
            id="measurement-approved"
            v-model="formData.approved"
          />
        </b-form-group>
        <b-form-group
          label="Measurement Image"
          label-for="measurement-image"
        >
          <sample-request-image-input
            ref="refImage"
            :model-value="pickedImage"
            @update:modelValue="value => pickedImage = value"
          />
        </b-form-group>
        <b-form-group
          label="Measurement Comments"
          label-for="measurement-comments"
        >
          <b-form-textarea
            id="measurement-comments"
            v-model="formData.comments"
            trim
            rows="3"
            max-rows="5"
          />
        </b-form-group>

        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          type="submit"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :disabled="invalid"
        >
          Update or Comment
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton, BForm, BFormCheckbox, BFormGroup, BFormTextarea,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import { ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import router from '@/router'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import SampleRequestImageInput from './SampleRequestImageInput.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormTextarea,

    SampleRequestImageInput,

    // Form Validation
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    sampleRequestData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const toast = useToast()

    const initData = () => {
      const data = {
        approved: props.sampleRequestData.measurement_approved,
        comments: '',
        measurements: [],
        sample_size: props.sampleRequestData.sample_size,
      }
      if (props.sampleRequestData.measurements.length > 0) {
        data.measurements = props.sampleRequestData.measurements
      } else {
        data.measurements = props.sampleRequestData.sizes.map(size => ({
          ref: size.ref,
          description: size.description,
          tol: size.tol,
          [props.sampleRequestData.sample_size]: size[props.sampleRequestData.sample_size],
        }))
      }
      return data
    }

    const formData = ref(initData())

    const pickedImage = ref(null)
    const refImage = ref()

    const onSubmit = () => {
      const sampleRequestData = new FormData()
      sampleRequestData.append('sample_request', router.currentRoute.params.id)
      sampleRequestData.append('type', 'Measurement')
      sampleRequestData.append('feed_vars', JSON.stringify(formData.value))
      if (pickedImage.value) {
        sampleRequestData.append('image', pickedImage.value)
      }
      store.dispatch('app-sample-request/addFeed', sampleRequestData)
        .then(() => {
          emit('refresh-data')
          formData.value.comments = ''
          refImage.value.removeImage()
          emit('scroll-to-bottom')
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Measurements updated',
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
        })
    }

    const validMeasure = content => {
      const measure = +content
      if (!measure) return 'Invalid number'
      return ''
    }

    const cellStyle = (record, item) => {
      if (item.name === 'measure') {
        const measure = +record.measure
        if (!measure) return {}

        const tol = +record.tol
        const size = +record[props.sampleRequestData.sample_size]
        if (measure < size - tol || measure > size + tol) {
          return { backgroundColor: 'red', color: 'white' }
        }
      }
      return {}
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      onSubmit,
      formData,
      pickedImage,
      refImage,

      refFormObserver,
      getValidationState,
      validMeasure,
      cellStyle,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
