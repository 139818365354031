<template>
  <div>
    <div
      v-if="previewImage"
      class="imagePreviewWrapper"
      :style="{ 'background-image': `url(${previewImage})` }"
      @click="removeImage"
    />
    <b-form-file
      :value="modelValue"
      size="sm"
      @input="pickImage"
    />
  </div>
</template>

<script>
import {
  BFormFile,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BFormFile,
  },
  props: {
    modelValue: {
      type: [File, Array],
      required: false,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const previewImage = ref(null)

    const pickImage = pickedImage => {
      if (pickedImage) {
        const reader = new FileReader()
        reader.onload = e => {
          previewImage.value = e.target.result
        }
        reader.readAsDataURL(pickedImage)
      } else {
        previewImage.value = null
      }
      emit('update:modelValue', pickedImage)
    }

    const removeImage = () => {
      previewImage.value = null
      emit('update:modelValue', null)
    }

    return {
      previewImage,

      pickImage,
      removeImage,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.imagePreviewWrapper {
    width: 250px;
    height: 250px;
    display: block;
    cursor: pointer;
    margin: 0 auto 30px;
    background-size: cover;
    background-position: center center;
}
</style>
