<template>
  <div>
    <validation-observer
      #default="{ invalid }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="onSubmit"
      >
        <!-- Embellishment -->
        <b-form-group
          label="Embellishment Approved"
          label-for="embellishment-approved"
        >
          <b-form-checkbox
            id="embellishment-approved"
            v-model="formData.approved"
          />
        </b-form-group>

        <b-form-group
          label="Embellishment Image"
          label-for="embellishment-image"
        >
          <sample-request-image-input
            ref="refImage"
            :model-value="pickedImage"
            @update:modelValue="value => pickedImage = value"
          />
        </b-form-group>

        <b-form-group
          label="Embellishment Comments"
          label-for="embellishment-comments"
        >
          <b-form-textarea
            id="embellishment-comments"
            v-model="formData.comments"
            trim
            rows="3"
            max-rows="5"
          />
        </b-form-group>

        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          type="submit"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :disabled="invalid"
        >
          Update or Comment
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton, BForm, BFormCheckbox, BFormGroup, BFormTextarea,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import { ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import router from '@/router'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import SampleRequestImageInput from './SampleRequestImageInput.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormTextarea,

    SampleRequestImageInput,

    // Form Validation
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    sampleRequestData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const toast = useToast()

    const formData = ref({
      approved: props.sampleRequestData.design_approved,
      comments: '',
    })

    const pickedImage = ref(null)
    const refImage = ref()

    const onSubmit = () => {
      const sampleRequestData = new FormData()
      sampleRequestData.append('sample_request', router.currentRoute.params.id)
      sampleRequestData.append('type', 'Embellishment')
      sampleRequestData.append('feed_vars', JSON.stringify(formData.value))
      if (pickedImage.value) {
        sampleRequestData.append('image', pickedImage.value)
      }
      store.dispatch('app-sample-request/addFeed', sampleRequestData)
        .then(() => {
          emit('refresh-data')
          formData.value.comments = ''
          refImage.value.removeImage()
          emit('scroll-to-bottom')
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Embellishment updated',
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
        })
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      onSubmit,
      formData,
      pickedImage,
      refImage,

      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
